<template>
  <v-container class="pa-0 body-card">
    <div id="spacer"></div>
    <div class="ChangePassword">

      <notification-popup  
        :dialog="notifSuccess"
        :contentMsg="$t('SuccessChangePassword')"
        :headerMsg="$t('Success')"
        :nameBtnNo="$t('labelNo')"
        :nameBtnYes="$t('labelOk')"
        imgSource="notification/success2.svg"
        disableBtnNo="true"
        typeModal="transaction"
        :onHandlerYes="buttonOke"
      />


      <notification-popup 
        :dialog="notifFailed"
        :contentMsg="notifFailedMsg"
        :headerMsg="$t('failed')"
        :nameBtnNo="$t('labelNo')"
        :nameBtnYes="$t('TryAgain')"
        colorIcon = "danger"
        typeModal="transaction"
        Icon="mdi-close-circle"
        disableBtnNo="true"
        imgSource="notification/failed.svg"
        :onHandlerYes="buttonOkeFailed"
      />

      <div class="item-add">
        <v-container class="pb-0">
          <v-row>
            <v-col cols="12" class="pt-0">
              <p class="text-capitalize font-weight-500 mb-0">{{$t('OldPassword')}}</p>
              <v-text-field
                v-model="old_password" 
                :append-icon="showOldPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showOldPassword ? 'text' : 'password'"
                @click:append="showOldPassword = !showOldPassword"
                :placeholder="$t('OldPassword')"
                :isDisabled="false"
                :isClearable="true"
                name="password"
                :color="$partnerACL.getButtonColor()"
                v-validate="'required|min:8|max:200'"
                :rules="[passwordRules.required, passwordRules.min, passwordRules.max]"
                dense
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="pt-0">
              <p class="text-capitalize font-weight-500 mb-0 mt-n3">{{$t('labelNewPassword')}}</p>
              <v-text-field
                v-model="new_password" 
                :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showNewPassword ? 'text' : 'password'"
                @click:append="showNewPassword = !showNewPassword"
                :placeholder="$t('labelNewPassword')"
                outlined
                :isDisabled="false"
                :isClearable="true"
                dense
                name="labelNewPassword"
                :color="$partnerACL.getButtonColor()"
                v-validate="'required|min:8|max:200'"
                data-vv-as="password"
                :rules="[passwordRules.required, passwordRules.min, passwordRules.max]"
                ref="new_password"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="pt-0">
              <p class="text-capitalize font-weight-500 mb-0 mt-n3">{{$t('ConfirmNewPassword')}}</p>
              <v-text-field
                x-small
                v-model="re_type_new_password" 
                :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showConfirmPassword ? 'text' : 'password'"
                @click:append="showConfirmPassword = !showConfirmPassword"
                :placeholder="$t('ConfirmNewPassword')"
                outlined
                :isDisabled="false"
                :isClearable="true"
                dense
                name="ConfirmNewPassword"
                :color="$partnerACL.getButtonColor()"
                v-validate="'required|min:8|max:200|confirmed:new_password'"
                data-vv-as="password"
                :rules="[passwordRules.required, passwordRules.min, passwordRules.max, passwordRules.match]"
              >
              </v-text-field>
            </v-col>
          </v-row>

          
      </v-container>
      </div>

      <div class="footer" justify="center">
        <v-row>
          <v-col class="text-center" cols="12">
            <!-- <v-btn
              v-if="$partnerACL.isShowButton('save')"
              :color="$partnerACL.getButtonColor()"
              rounded
              class="btn-click-change-password"
              width="70%"
              :disabled="disabledBtn"
              @click="clickUpdatePassword()"
            >
              {{$t('done')}}
            </v-btn> -->
            <ac-button 
              :active="$partnerACL.isShowButton('save')"
              :name="$t('Save')" 
              :on-click-handler="clickUpdatePassword"
              :is-disabled="disabledBtn"
              :width="'80%'"
            />
          </v-col>
        </v-row>
      </div>
    </div>
  </v-container>
</template>

<script>
import NotificationPopup from "@/components/NotificationPopup.vue";
import AcButton from "@/components/AcButton.vue";

export default {
  name: 'ChangePassword',
  components: { NotificationPopup, AcButton, },
  data() {
    return {
      notifSuccess: false,
      notifFailed: false,
      showOldPassword: false,
      showNewPassword: false,
      showConfirmPassword: false,
      disabledBtn: true,
      passwordMatch: false,
      old_password: "",
      new_password: "",
      re_type_new_password: "",
      passwordRules: {
        required: value => !!value || this.$t("ConfirmPasswordRequired"),
        min: value => value.length > 7 || this.$t("ConfirmPasswordMin"),
        max: value => value.length <= 200 || this.$t("ConfirmPasswordMax"),
        match: value => value === this.new_password || this.$t("ConfirmPasswordMatch"),
      },
      message: "",
      notifFailedMsg: ""
      // rules: {
      //   required: value => !!value || this.$t("Required"),
      //   min: (v) => v.length >= 6 || this.$t("MinSixCharacters"),
      //   counter: value => value.length <= 50 || this.$t("MaxFiftycharacters"),
      //   email: value => {
      //     const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      //     return pattern.test(value) || 'Invalid e-mail.'
      //   },
      // },
    }
  },
  computed: {
    isButtonAdd() {
      let data = true;
      if (this.old_password == "" && this.new_password == "" && this.re_type_new_password == "") {
        data = true;
      } else if (this.old_password == "") {
        data = true;
      } else if (this.new_password == "") {
        data = true;
      } else if (this.re_type_new_password == "") {
        data = true;
      } else if (this.isPassword) {
        data = true;
      } else {
        data = false;
      }
      return data
    },
    isPassword() {
      let password_ = false;
      if (this.new_password != "" && this.new_password.length >= 6 && this.re_type_new_password != "") {
        if (this.new_password != this.re_type_new_password) {
          password_ = true;
        }
      }
      return password_
    }
  },
  watch: {
    isButtonAdd() {
      this.disabledBtn = this.isButtonAdd;
    },
    isPassword() {
      this.message = "NewPasswordDoesntMatch"
    }
  },
  methods: {
    clickUpdatePassword() {

      const payload = {
        old_password: this.old_password,
        new_password: this.new_password,
        confirm_password: this.re_type_new_password,
      };

      this.$store
        .dispatch("customer/changePassword", payload)
        .then((response) => {
          if (response.status == true) {
            this.notifSuccess = true
          } else {
            this.notifFailedMsg = this.$t(response.message)
            this.notifFailed = true
          }
        })
        .catch((err) => {
          // this.notifFailedMsg = this.$t('notifFailedChangePassword')
          // this.notifFailed = true
          console.log(err);
        });
    },
    buttonOke() {
      // this.notifSuccess = false
      // if (this.notifSuccess == false) this.$router.push({ name: "About" });
      this.logout();
    },
    buttonOkeFailed() {
      this.notifFailed = false
    },
    logout() {
      this.$store
        .dispatch("auth/logout")
        .then(() => {
          this.$store.dispatch("auth/resetInitialPath", JSON.parse(localStorage.getItem("InitialPath")))
          location.reload()
        })
        .catch((err) => {
          console.log(err);
        });
    },
  }
}
</script>

<style lang="scss" scoped>
.body-card {
  background-color: #f5fff7;
  height: 100%;
  width: 100%;
  padding-top: 0;
}

#spacer {
  background: #f5fff7;
  padding: 6px;
}

.font-weight-500 {
  font-weight: 500;
}

.v-text-field--outlined fieldset {
  border-color: #5DB968 !important
}

.v-text-field--outlined fieldset {
  border-color: #5DB968 !important;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 70px;
  text-align: center;
  mix-blend-mode: normal;
}

.error-password {
  color: #ff5252;
}

/* If the screen size is 601px or more, set the font-size of <div> to 80px */
@media only screen and (min-width: 601px) {

  .item-add {
    background-color: #FFFFFF;
    width: 100%;
  }

  .btn-click-change-password {
    text-transform: capitalize;
    left: 0;
  }
}

/* If the screen max size is 600px, set the font-size of <div> to 80px */
@media only screen and (max-width: 600px) {

  .item-add {
    width: 100%;
    background-color: #FFFFFF;
  }

  .btn-click-change-password {
    text-transform: capitalize;
    left: 0;
  }
}
</style>